import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import {
    AgeFormula,
    I18n,
    Material,
    MaterialModifyReq,
    MaterialUploadReq,
    Product, ProductCommonType,
    ProductFrequency,
    ProductStatus,
    ProductType,
    QuotationFormula
} from "../../api/types";
import { PropertySelectOption } from "../../shared/components/property-select/property-select.component";
import { ProductService } from "../product.service";
import { ProductPropertyDetailComponent } from "../property/product-property-detail.component";
import { isNotNullOrUndefined, isNullOrUndefined } from "../../common/utils";
import { MetadataService } from "../../shared/services/metadata.service";
import { ProductFrequencyPipe } from "../../shared/pipes/product-frequency.pipe";
import { ProductTypePipe } from "../../shared/pipes/product-type.pipe";
import { ProductStatusPipe } from "../../shared/pipes/product-status.pipe";
import { ProductQuotationFormulaPipe } from "../../shared/pipes/product-quotation-formula.pipe";
import { NzNotificationService } from "ng-zorro-antd/notification";
import { ProductCompanyRegionPipe } from "../../shared/pipes/product-company-region.pipe";
import { ProductCompanyRegion } from "../product-types";
import { ProductCommonTypePipe } from "../../shared/pipes/product-common-type.pipe";
import { PropertyTextEditType } from "../../shared/components/property-text/property-text-edit.component";
import { DataService } from "../../shared/services/data.service";

@Component({
    selector: 'app-product-product-info',
    templateUrl: './product-product-info.component.html',
    styleUrls: ['./product-product-info.component.less']
})
export class ProductProductInfoComponent implements OnInit {

    product: Product;
    @Input() editing: boolean = false;

    @Output()
    productUpdated: EventEmitter<Product> = new EventEmitter<Product>();

    spinning: boolean = false
    customerOfTheProductArr = [];

    commonTypeOptions: PropertySelectOption[] = []
    typeOptions: PropertySelectOption[] = []
    statusOptions: PropertySelectOption[] = []
    categoryOptions: PropertySelectOption[] = []
    companyOptions: PropertySelectOption[] = []
    frequencyOptions: PropertySelectOption[] = []
    currencyOptions: PropertySelectOption[] = []
    quotationFormulaOptions: PropertySelectOption[] = []
    regionOptions: PropertySelectOption[] = []
    ageFormulaOptions: PropertySelectOption[] = [];

    @ViewChild('propertyGroups')
    propertyGroupsComponent: ProductPropertyDetailComponent;

    constructor(private productService: ProductService,
                private metadataService: MetadataService,
                private productCommonTypePipe: ProductCommonTypePipe,
                private productTypePipe: ProductTypePipe,
                private productStatusPipe: ProductStatusPipe,
                private productFrequencyPipe: ProductFrequencyPipe,
                private productQuotationFormulaPipe: ProductQuotationFormulaPipe,
                private productCompanyRegionPipe: ProductCompanyRegionPipe,
                private notification: NzNotificationService,
                public dataService: DataService,) {
    }

    ngOnInit(): void {

        let productCommonTypes: ProductCommonType[] = [ProductCommonType.LIFE, ProductCommonType.GI, ProductCommonType.MPF]
        this.commonTypeOptions = productCommonTypes.map(commonType => {
            return new PropertySelectOption(this.productCommonTypePipe.transform(commonType), commonType)
        })

        let productTypes: ProductType[] = [ProductType.BASIC, ProductType.RIDER]
        this.typeOptions = productTypes.map(productType => {
            return new PropertySelectOption(this.productTypePipe.transform(productType), productType)
        })

        let productStatuses: ProductStatus[] = [ProductStatus.SELLING, ProductStatus.DISCONTINUED]
        this.statusOptions = productStatuses.map(productStatus => {
            return new PropertySelectOption(this.productStatusPipe.transform(productStatus), productStatus)
        })

        let productFrequencies: ProductFrequency[] = [ProductFrequency.SINGLE_PAYMENT, ProductFrequency.MONTHLY, ProductFrequency.QUARTERLY, ProductFrequency.SEMI_ANNUALLY, ProductFrequency.ANNUALLY]
        this.frequencyOptions = productFrequencies.map(frequency => {
            return new PropertySelectOption(this.productFrequencyPipe.transform(frequency), frequency)
        })

        let quotationFormulas: QuotationFormula[] = [QuotationFormula.PREMIUM_RATE, QuotationFormula.PREMIUM_TABLE]
        this.quotationFormulaOptions = quotationFormulas.map(quotationFormula => {
            return new PropertySelectOption(this.productQuotationFormulaPipe.transform(quotationFormula), quotationFormula)
        })

        let productCompanyRegions: ProductCompanyRegion[] = [
            ProductCompanyRegion.HONGKONG,
            ProductCompanyRegion.MACAO,
            ProductCompanyRegion.SINGAPORE,
            ProductCompanyRegion.CANADA,
            ProductCompanyRegion.ASIA,
            ProductCompanyRegion.BERMUDA,
            ProductCompanyRegion.USA,
        ]
        this.regionOptions = productCompanyRegions.map(region => {
            return new PropertySelectOption(this.productCompanyRegionPipe.transform(region), region)
        })

        this.ageFormulaOptions?.push(new PropertySelectOption('周岁', AgeFormula.CURRENT_BIRTHDAY));
        this.ageFormulaOptions?.push(new PropertySelectOption('下一次生日', AgeFormula.NEXT_BIRTHDAY));
        this.ageFormulaOptions?.push(new PropertySelectOption('最近生日', AgeFormula.NEAREST_BIRTHDAY));

        this.productService.categories()
            .subscribe(
                data => {
                    data.forEach(category => {
                        this.categoryOptions?.push(new PropertySelectOption(category.name, category.code));
                    })
                },
                error => {
                });
        this.productService.companies()
            .subscribe(
                data => {
                    data.forEach(company => {
                        this.companyOptions?.push(new PropertySelectOption(company.name, company.code));
                    })
                },
                error => {
                });
        this.metadataService.currencies()
            .subscribe(
                data => {
                    data.forEach(currency => {
                        this.currencyOptions?.push(new PropertySelectOption(currency, currency));
                    })
                },
                error => {
                });

        this.customerOfTheProduct();

        this.getProductInfo();
    }

    @Input()
    get value(): Product {
        return this.product;
    }

    set value(val: Product) {
        this.product = val;
        if (isNotNullOrUndefined(this.propertyGroupsComponent)) {
            this.propertyGroupsComponent.code = this.product.code;
        }
    }

    onEdit(): void {
        this.editing = true;
    }

    onCancelEdit(): void {
        this.editing = false;
    }

    onSave(): void {
        this.spinning = true;
        let code = this.product.code;
        this.productService.productUpdate(code, this.product)
            .subscribe(
                data => {
                    this.getProductInfo();
                    this.notification.success('保存產品信息成功', '');
                    this.editing = false;
                    this.spinning = false;
                    this.productUpdated.emit(this.value);
                },
                error => {
                    this.notification.error('保存產品信息失败', '');
                    this.spinning = false;
                });
    }

    get uploadReq(): MaterialUploadReq {
        return MaterialUploadReq.fromProduct(this.product);
    }

    onFilesChange(files: Material[]) {
        this.productService.productFilesUpdate(this.product.code, files)
            .subscribe(
                data => {
                    this.getProductInfo();
                },
                error => {
                    this.getProductInfo();
                });
    }

    getProductInfo() {
        this.spinning = true;
        this.productService.productInfo(this.product.code)
            .subscribe(
                data => {
                    this.spinning = false;
                    if (data.nameI18n == null) {
                        data.nameI18n = new I18n();
                    }
                    if (data.descI18n == null) {
                        data.descI18n = new I18n();
                    }
                    this.product = data;
                },
                error => {
                    this.spinning = false;
                });
    }

    _frequencyNames: string[] = []

    get frequencyNames(): string[] {

        if (isNullOrUndefined(this.product.frequencies))
            return [];

        this._frequencyNames = this.product.frequencies.map(level => {
            return this.productFrequencyPipe.transform(level)
        });

        return this._frequencyNames;
    }

    onFileModified(materialModifyReq: MaterialModifyReq) {
        this.spinning = true
        this.productService.modifyProductFile(this.product.code, materialModifyReq)
            .subscribe(
                data => {
                    this.spinning = false;
                    if (!data) {
                        this.notification.error('文件重命名失敗', materialModifyReq.file.fileName);
                    }
                    this.getProductInfo();
                },
                error => {
                    this.spinning = false;
                });

    }

    customerOfTheProduct(): void {
        this.productService.getAllInstance(this.product.code)
            .subscribe(
                data => {
                    this.customerOfTheProductArr = data;
                }
            )
    }
}

import { Component, OnInit } from '@angular/core';
import {
    I18n,
    Knowledge,
    KnowledgeSearch,
    KnowledgeType,
    PagedResp,
    Product,
    Proposal,
    ProposalStatistic
} from "../api/types";
import { KnowledgeService } from "./knowledge.service";
import { NzModalService } from "ng-zorro-antd/modal";
import { ProductProductInfoComponent } from "../product/product/product-product-info.component";
import { NzDrawerService } from "ng-zorro-antd/drawer";
import { KnowledgeInfoComponent } from "./knowledge-info.component";
import { KnowledgeCreateComponent } from "./components/knowledge-create.component";
import { ArticleSearch } from "../article/article-types";
import { PropertySelectOption } from "../shared/components/property-select/property-select.component";
import { ProductService } from "../product/product.service";
import { ProductCompanySearch } from "../product/product-types";
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from "@angular/router";

@Component({
    selector: 'app-knowledges',
    templateUrl: './knowledges.component.html',
    styleUrls: ['./knowledges.component.less']
})
export class KnowledgesComponent implements OnInit {

    spinning = false;

    loading = false;

    proposalStatistic: ProposalStatistic;

    knowledges: PagedResp<Knowledge>;

    search: KnowledgeSearch = new KnowledgeSearch();

    filterPopoverVisible = false;

    companySearch: ProductCompanySearch = new ProductCompanySearch()
    companyOptions: PropertySelectOption[] = []

    types: KnowledgeType[];
    typeOptions: PropertySelectOption[] = []

    constructor(private router: Router,
                private knowledgeService: KnowledgeService,
                private productService: ProductService,
                private modalService: NzModalService,
                private drawerService: NzDrawerService,
    ) {
    }

    ngOnInit(): void {
        this.loading = true;
        this.knowledgeService.knowledges(this.search)
            .subscribe(
                data => {
                    this.loading = false;
                    this.knowledges = data;
                },
                error => {
                    this.loading = false;
                });

        this.loadCompanies();
        this.loadTypes();

    }

    loadCompanies(): void {
        this.loading = true;
        this.productService.companies(this.companySearch)
            .subscribe(
                data => {
                    this.loading = false;
                    this.companyOptions = [];
                    this.companyOptions.push(new PropertySelectOption('全部公司', null));
                    data.forEach(company => {
                        this.companyOptions.push(new PropertySelectOption(company.name, company.code));
                    })
                },
                error => {
                    this.loading = false;
                });
    }

    loadTypes(): void {
        this.loading = true;
        this.knowledgeService.types()
            .subscribe(
                data => {
                    this.loading = false
                    this.types = data;
                    this.typeOptions = [];
                    this.typeOptions.push(new PropertySelectOption('所有类型', null));
                    data.forEach(type => {
                        this.typeOptions.push(new PropertySelectOption(type.name, type.id));
                    })
                },
                error => {
                    this.loading = false
                });
    }

    onSearch(pageNum: number) {
        this.search.pageNum = pageNum
        this.loading = true;
        this.knowledgeService.knowledges(this.search)
            .subscribe(
                data => {
                    this.loading = false;
                    this.knowledges = data;
                },
                error => {
                    this.loading = false;
                });
    }

    onReload() {
        this.loading = true;
        this.search.pageNum = 1;
        this.knowledgeService.knowledges(this.search)
            .subscribe(
                data => {
                    this.loading = false;
                    this.knowledges = data;
                },
                error => {
                    this.loading = false;
                });
    }

    onResetSearch(): void {
        this.search = new KnowledgeSearch();
        this.onReload();
    }

    onKnowledgeInfo(knowledge: Knowledge) {
        this.spinning = true
        this.knowledgeService.knowledgeInfo(knowledge.id)
            .subscribe(
                data => {
                    this.spinning = false
                    this.openInfoDrawer(data);
                },
                error => {
                    this.spinning = false
                });
    }

    openInfoDrawer(knowledge: Knowledge) {
        const drawerRef = this.drawerService.create<KnowledgeInfoComponent, { value: Knowledge }, string>({
            nzWidth: 800,
            nzContent: KnowledgeInfoComponent,
            nzContentParams: {
                knowledge: knowledge,
                editing: false
            }
        });

        drawerRef.afterOpen.subscribe(() => {
            let component = drawerRef.getContentComponent()
            component.knowledgeUpdated
                .subscribe(product => {
                    this.onSearch(this.search.pageNum);
                })
        });

        drawerRef.afterClose.subscribe(data => {
        });
    }

    onDelete(knowledge: Knowledge) {
        this.modalService.confirm({
            nzTitle: '是否确定删除「' + knowledge.title + '」？',
            nzContent: '<b style="color: #ff0048; font-size: 16px">请谨慎删除，删除后将无法恢复知識文章</b>',
            nzOkText: '删除',
            nzOkType: 'primary',
            nzCancelText: '取消',
            nzOnOk: () => {
                this.loading = true;
                this.knowledgeService.knowledgeDelete(knowledge.id)
                    .subscribe(
                        data => {
                            this.loading = false;
                            this.onSearch(this.search.pageNum);
                        },
                        error => {
                            this.loading = false;
                        });
            },
            nzOnCancel: () => {
            }
        });
    }

    onNotify(knowledge: Knowledge): void {
        this.spinning = true
        this.knowledgeService.notify(knowledge.id)
            .subscribe(
                data => {
                    this.spinning = false;
                    this.onReload();
                },
                error => {
                    this.spinning = false;
                });
    }

    onCreate(): void {
        const drawerRef = this.drawerService.create<KnowledgeCreateComponent, { value: Knowledge }, string>({
            nzWidth: 800,
            nzContent: KnowledgeCreateComponent,
            nzContentParams: {}
        });

        drawerRef.afterOpen.subscribe(() => {
            let component = drawerRef.getContentComponent()
            component.drawerRef = drawerRef;
            component.knowledgeCreated
                .subscribe(
                    knowledge => {
                        this.onReload();
                    }
                );
        });

        drawerRef.afterClose.subscribe(data => {
        });
    }

    onNavigate(knowledge: Knowledge) {

        const url = this.router.serializeUrl(
            this.router.createUrlTree(['/public/knowledge', knowledge.id])
        );
        console.error(url);
        window.open('#' + url, '_blank');


        // const url = `https://mp.lifebee.tech/knowledge/detail?id=${knowledge.id}&instance=lifebee`;
        // window.open(url, '_blank');


        // this.router.navigate(['public/knowledge', knowledge.id]);
    }

    isTopChange(info) {
        this.loading = true;
        this.knowledgeService.top({id: info.id, isTop: info.isTop})
            .subscribe(
                data => {
                    this.onSearch(this.search.pageNum)
                },
                error => {
                    // this.loading = false;
                    this.onSearch(this.search.pageNum)
                });
    }
}

import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    TemplateRef,
    ViewChild
} from '@angular/core';
import {
    Material, MaterialFilterReq,
    MaterialModifyReq,
    MaterialUploadReq,
    MaterialUploadTokenReq
} from "../../../api/types";
import {FileItem, FileUploader, Headers, ParsedResponseHeaders} from "ng2-file-upload";
import {MaterialService} from "../../services/material.service";
import {ToastrService} from "ngx-toastr";
import {isNullOrUndefined} from "../../../common/utils";
import {DownloadService} from "../../services/download.service";
import {NzModalRef, NzModalService} from "ng-zorro-antd/modal";
import * as _ from "lodash";
import {HttpClient} from "@angular/common/http";
import {map} from "rxjs/operators";
import {ContentTypesService} from "../../services/content-types.service";

@Component({
    selector: 'app-material-list',
    templateUrl: './material-list.component.html',
    styleUrls: ['./material-list.component.less']
})
export class MaterialListComponent implements OnInit, AfterViewInit {

    @Input()
    spinning: boolean = false;

    @Input()
    materials: Material[] = [];

    constructor(
        private downloadService: DownloadService,
        private materialService: MaterialService,
        private modalService: NzModalService,
        private contentTypeService: ContentTypesService,
        private toastr: ToastrService,
        private httpClient: HttpClient) {
    }

    ngOnInit(): void {
        this.makeAllVisible();
    }

    ngAfterViewInit(): void {
        // const options = this.uploader.options;
        // options.additionalParameter = {
        //     "uploadTo": this.uploadReq.uploadTo,
        //     "key": this.uploadReq.key
        // };
        // this.uploader.setOptions(options);
    }

    extension(filePath: string): string {
        if (isNullOrUndefined(filePath) || filePath.length === 0) return ""
        return filePath.substring(filePath.lastIndexOf(".") + 1).toUpperCase()
    }

    onDownload(material: Material) {
        let path = encodeURI(material.filePath).replace(/\+/gi, '%2B');
        let fileName = encodeURI(material.fileName);
        this.downloadService.download(path, fileName);
        // this.downloadService.showOpenDialog(material.fileName);
    }

    canPreview(material: Material): boolean {
        let result;
        const fileName = material.fileName ?? '';
        const ext = fileName.substr(fileName.lastIndexOf('.') + 1)?.toLowerCase();
        switch (ext) {
            case 'png':
            case 'jpeg':
            case 'jpg':
            case 'pdf':
                result = true;
                break;
            default:
                result = false;
                break;
        }
        return result;
    }

    onPreview(material: Material) {

        const fileName = material.fileName ?? '';
        const ext = fileName.substr(fileName.lastIndexOf('.') + 1)?.toLowerCase();
        switch (ext) {
            case 'png':
            case 'jpeg':
            case 'jpg':
            case 'pdf':
                this.onMaterialPreview(material);
                break;
            default:
                let path = encodeURI(material.filePath).replace(/\+/gi, '%2B');
                window.open(path).focus();
                break;
        }

    }

    onMaterialPreview(material: Material): void {
        console.log(material.filePath);
        let path = encodeURI(material.filePath).replace(/\+/gi, '%2B');
        console.log(path);
        const contentType = this.contentTypeService.get(material.filePath);
        this.httpClient
            .get(path, {
                responseType: 'arraybuffer'
            }).pipe(map((arrayBuffer: ArrayBuffer) => new Uint8Array(arrayBuffer)))
            .subscribe(
                byteArray => {
                    const url = window.URL.createObjectURL(new Blob([byteArray], {type: contentType}));
                    window.open(url);
                }
            );
    }

    makeAllVisible(): void {
        if (this.materials) {
            for (let material of this.materials) {
                material.visible = true;
            }
        }
    }
}

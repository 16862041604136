<div *ngIf="information" style="padding: 0 20px 0 20px">

    <div class="profile-large-title">
        <span style="font-weight: bold; width: 100%; font-size: 28px;">{{ information.title }}</span>
    </div>

    <app-property-text [title]="'時間'" [value]="information.addTime | date: 'yyyy-MM-dd'" [editing]="false" [editable]="false"></app-property-text>

<!--    <app-property-datetime [title]="'生效日期'" [(value)]="information.startEffectiveDate"-->
<!--                           [editable]="false" [editing]="false"-->
<!--                           format="yyyy-MM-dd"></app-property-datetime>-->

<!--    <app-property-datetime [title]="'失效日期'" [(value)]="information.endEffectiveDate"-->
<!--                           [editable]="false" [editing]="false"-->
<!--                           format="yyyy-MM-dd"></app-property-datetime>-->

    <nz-tabset [nzSize]="'large'" nzCentered>
        <nz-tab nzTitle="内容">
            <div style="margin-top: 10px; padding-bottom: 60px;">
                <div style="margin-top: 10px; padding-bottom: 60px;">

                    <app-property-text [type]="contentTextType" [title]="''"
                                       [(value)]="information.content" [editable]="false"></app-property-text>

                </div>
            </div>
        </nz-tab>
        <nz-tab nzTitle="附件">
            <app-material-list [materials]="information.attachments"></app-material-list>
        </nz-tab>
    </nz-tabset>
</div>

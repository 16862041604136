import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { InformationService } from "../information.service";
import { Information, InformationSearch, PagedResp, ReleaseType } from "../../api/types";
import { ToastrService } from "ngx-toastr"; // 提示插件
import { NzDrawerService } from "ng-zorro-antd/drawer";
import { NzModalService } from "ng-zorro-antd/modal";
import { isNotNullOrUndefined, isNullOrUndefined } from "../../common/utils";
import { ProductService } from "../../product/product.service";
import { InformationSearchComponent } from "./information-search.component";
import { InformationCreateComponent } from "./information-create.component"
import { InformationInfoComponent } from "./information-info.component"
import { PropertySelectOption } from "../../shared/components/property-select/property-select.component";
import { DataService } from "../../shared/services/data.service";
import { Router } from "@angular/router";
import { formatDate } from '@angular/common';

@Component({
    selector: 'app-product-home',
    templateUrl: './information-home.component.html',
    styleUrls: ['./information-home.component.less']
})
export class InformationHomeComponent implements OnInit, AfterViewInit {

    loading: boolean = false;
    spinning: boolean = false;
    search: InformationSearch = new InformationSearch();
    informations: PagedResp<Information>;
    tags: string[] = []
    typeOptions: PropertySelectOption[] = [];
    companyOptions: PropertySelectOption[] = [];
    tagsOptions:  PropertySelectOption[] = [];

    time: Date[] = null;

    modalVisible: boolean = false;

    filterPopoverVisible = false;

    constructor(private informationService: InformationService,
                private ProductService: ProductService,
                private drawerService: NzDrawerService,
                private modalService: NzModalService,
                private dataService: DataService,
                private productService: ProductService,
                private router: Router,
                private toastr: ToastrService) {

        this.typeOptions?.push(new PropertySelectOption('全部', null));
        this.typeOptions?.push(new PropertySelectOption('行政通知', ReleaseType.ADMINISTRATION_NOTICE));
        this.typeOptions?.push(new PropertySelectOption('產品動態', ReleaseType.PRODUCT_NEWS));
        this.typeOptions?.push(new PropertySelectOption('一般資訊', ReleaseType.GENERAL_INFO));
        this.typeOptions?.push(new PropertySelectOption('產品優惠', ReleaseType.PRODUCT_PROMOTION));

        this.productService.companies()
            .subscribe(
                data => {
                    this.companyOptions?.push(new PropertySelectOption('全部公司', null));
                    data.forEach(company => {
                        this.companyOptions?.push(new PropertySelectOption(company.name, company.code));
                    })
                },
                error => {
                });
        this.informationService.tags()
            .subscribe(
                data => {
                    data.name.forEach(tag => {
                        this.tagsOptions?.push(new PropertySelectOption(tag, tag));
                    })
                },
                error => {
                });
    }

    @ViewChild('search')
    releaseSearchComponent: InformationSearchComponent;

    ngAfterViewInit(): void {
    }

    ngOnInit(): void {
        this.load(this.search)
        this.getTag()
    }

    getTag(): void {
        this.informationService.tags()
            .subscribe(
                data => {
                    this.tags = data.name
                    data.name.forEach(tag => {
                        this.tagsOptions?.push(new PropertySelectOption(tag, tag));
                    })
                },
                error => {
                });
    }

    load(search: InformationSearch): void {
        this.loading = true;
        this.search = search;
        this.informationService.list(search)
            .subscribe(
                data => {
                    this.loading = false;
                    this.informations = data
                },
                error => {
                    this.loading = false;
                    // this.releaseData = []
                });
    }

    editTags(): void {
        this.modalVisible = true;
    }

    visibleChange(): void {
        this.modalVisible = false
        this.getTag()
    }

    onCreate() {
        const drawerRef = this.drawerService.create<InformationCreateComponent, { value: string }, string>({
            nzWidth: 1024,
            nzContent: InformationCreateComponent,
            nzContentParams: {}
        });

        drawerRef.afterOpen.subscribe(() => {
            const component = drawerRef.getContentComponent();
            component.informationCreated
                .subscribe(
                    data => {
                        drawerRef.close();
                        this.load(this.search);
                        this.openInfoDrawer(data);
                    },
                    error => {
                    });
        });

        drawerRef.afterClose.subscribe(data => {
        });
    }

    openInfoDrawer(information: Information): void {
        this.spinning = true
        this.informationService.info(information.id)
            .subscribe(
                information => {
                    this.spinning = false
                    const drawerRef = this.drawerService.create<InformationInfoComponent, { value: string }, string>({
                        nzWidth: 1024,
                        nzContent: InformationInfoComponent,
                        nzContentParams: {
                            information
                        }
                    });

                    drawerRef.afterOpen.subscribe(() => {
                        const component = drawerRef.getContentComponent()
                    });

                    drawerRef.afterClose.subscribe(() => {
                        this.load(this.search);
                    });
                },
                error => {
                    this.spinning = false;
                });
    }

    onReload(): void {
        this.search.pageNum = 1;
        this.load(this.search);
    }

    onDelete(information: Information): void {
        this.modalService.confirm({
            nzTitle: '是否确定删除？',
            nzContent: '<b style="color: #a21f1f; font-size: 16px">请谨慎删除，删除后将无法恢复</b>',
            nzOkText: '删除',
            nzOkType: 'primary',
            nzCancelText: '取消',
            nzOnOk: () => {
                this.loading = true;
                this.informationService.delete(information.id)
                    .subscribe(
                        data => {
                            this.loading = false;
                            this.onSearch(this.search.pageNum);
                        },
                        error => {
                            this.loading = false;
                        });
            },
            nzOnCancel: () => {
            }
        });
    }

    onSearch(pageNum: number) {
        this.search.pageNum = pageNum
        this.loading = true;
        this.informationService.list(this.search)
            .subscribe(
                data => {
                    this.loading = false;
                    this.informations = data;
                },
                error => {
                    this.loading = false;
                });
    }

    onResetSearch(): void {
        this.search = new InformationSearch();
        this.onReload();
    }

    get selectedProducts(): Information[] {
        let releaseData: Information[] = []
        if (!isNullOrUndefined(this.informations) && !isNullOrUndefined(this.informations.list)) {
            releaseData = this.informations.list.filter(releaseData => {
                return releaseData.checked
            })
        }
        return releaseData;
    }

    checkAllEvent(checked: boolean) {
        if (isNotNullOrUndefined(this.informations) && isNotNullOrUndefined(this.informations.list)) {
            this.informations.list.forEach(product =>
                product.checked = checked
            )
        }
    }

    // 同步資訊
    onInformationInstanceSync(): void {
        this.dataService.informationToSync = this.selectedProducts;
        this.router.navigate(['/operator/information/instance-sync']);
    }

    tagsChange(tags: string[]) {
        this.informationService.tagsUpdate(tags)
            .subscribe(
                data => {
                },
                error => {
                });
    }

    onTimeChange(time: Date[]) {
        const formatter = 'yyyy-MM-dd';
        this.time = time;
        if (time && time.length > 0) {
            this.search.beginTime = formatDate(time[0], formatter, 'zh-Hans-CN');
        }
        if (time && time.length > 1) {
            this.search.endTime = formatDate(time[1], formatter, 'zh-Hans-CN');
        }
        console.log(this.search);
    }

    export() {
        this.spinning = true;
        this.informationService.export()
            .subscribe(
                data => {
                    this.spinning = false;
                },
                error => {
                    this.spinning = false;
                });

    }

    onNavigate(information: Information) {

        const url = this.router.serializeUrl(
            this.router.createUrlTree(['/public/information', information.id])
        );
        console.error(url);
        window.open('#' + url, '_blank');


        // const url = `https://mp.lifebee.tech/knowledge/detail?id=${knowledge.id}&instance=lifebee`;
        // window.open(url, '_blank');


        // this.router.navigate(['public/knowledge', knowledge.id]);
    }

}

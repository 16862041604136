import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { isNotNullOrUndefined } from "../../../common/utils";
import * as dayjs from 'dayjs'

@Component({
  selector: 'app-property-datetime',
  templateUrl: './property-datetime.component.html',
  styleUrls: ['./property-datetime.component.less']
})
export class PropertyDatetimeComponent implements OnInit {

    @Input() title: string;
    @Input() editing: boolean = false;
    @Input() editable: boolean = false;
    @Input() format: string = 'yyyy-MM-dd HH:mm:ss';
    @Input() showTime: boolean | object = false;

    @Output()
    valueChange: EventEmitter<number> = new EventEmitter<number>();

    @Input()
    get value() {
        return this.datetime?.getTime();
    }
    set value(val) {
        if (isNotNullOrUndefined(val)) {
            this.datetime = new Date(val);
        }
    }

    constructor() {
    }

    ngOnInit(): void {
    }

    datetime: Date

    onChange(date: Date) {
        console.log(this.datetime);
        if (this.showTime && date) {
            const dayjsDate = dayjs(date);
            const now = dayjs();
            if (dayjsDate.hour() === now.hour() && dayjsDate.minute() === now.minute()) {
                this.datetime = dayjsDate.hour(0).minute(0).toDate();
            } else {
                // 处理完后再转换回 Date 对象
                this.datetime = dayjsDate.toDate();
            }
            this.valueChange.emit(this.datetime?.getTime());
        } else {
            this.datetime = date;
            this.valueChange.emit(this.datetime?.getTime());
        }
    }
}

import { Component, OnInit } from '@angular/core';
import { AuthService } from './auth.service';
import { NavigationExtras, Router } from '@angular/router';
import sha256 from 'crypto-js/sha256';
import { ToastrService } from 'ngx-toastr';
import { LoginByAccountRequest, LoginByEmailRequest, Operator } from "../api/types";
import { Environment } from "../api/environment";
import { NzDrawerService } from "ng-zorro-antd/drawer";
import { AccountsComponent } from "./accounts/accounts.component";

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.less']
})
export class LoginComponent implements OnInit {

    loginByEmailReq: LoginByEmailRequest = {email: '', password: ''};
    loginByAccountReq: LoginByAccountRequest = {account: '', password: ''};
    // loginByAccountReq: LoginByAccountRequest = new LoginByAccountRequest();

    logging = false;

    // rememberPassword = false;

    constructor(
        private authService: AuthService,
        private router: Router,
        private drawerService: NzDrawerService,
        private toastr: ToastrService) {
    }

    ngOnInit(): void {

        if (this.authService.env == Environment.DEV || this.authService.env == Environment.BETA) {
            this.loginByAccountReq = {account: 'beefintech', password: 'centre@beta'};
            this.loginByEmailReq = {email: 'beefintech@beefintech.net', password: 'centre2024@BeeFintech'};
        }

        const operator: Operator = this.authService.getOperator();
        this.authService.setOperator(null);
        if (operator != null) {
            this.router.navigate(['/operator']);
        }
    }

    loginByAccount(): void {
        this.logging = true;
        this.authService.loginByAccount(this.loginByAccountReq.account, sha256(this.loginByAccountReq.password).toString(),
            result => {
                this.logging = false;
                const redirect = this.authService.redirectUrl ? this.authService.redirectUrl : '/operator';
                const navigationExtras: NavigationExtras = {
                    queryParamsHandling: 'preserve'
                };
                // Redirect the user
                this.router.navigate([redirect], navigationExtras);
            },
            error => {
                this.logging = false;
            });
    }

    onAccounts(): void {
        const drawerRef = this.drawerService.create<AccountsComponent, { value: any }, string>({
            nzWidth: 450,
            nzContent: AccountsComponent,
            nzContentParams: {}
        });

        drawerRef.afterOpen.subscribe(() => {
            let component = drawerRef.getContentComponent()
            component.accountSelected.subscribe(
                account => {
                    drawerRef.close();
                    this.authService.env = account.env;
                    this.loginByAccountReq.account = account.account;
                    this.loginByAccountReq.password = account.password;
                    this.loginByAccount();
                },
                error => {
                }
            )
        });

        drawerRef.afterClose.subscribe(data => {
            if (typeof data === 'string') {
            }
        });
    }
}

<ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
<nz-spin [nzIndicator]="indicatorTemplate" [nzSpinning]="spinning">

    <div *ngIf="materials">
        <div *ngFor="let material of materials; let i = index">
            <div class="material" *ngIf="material.visible">
                <div class="material-file-icon">
                    <span>{{extension(material.filePath)}}</span>
                </div>
                <div class="material-file-elem">
                    <div class="material-file-name">{{material.fileName}}</div>
<!--                    <div class="material-file-path">-->
<!--                        {{material.filePath}}-->
<!--                    </div>-->
                    <div class="material-file-tag">
                        <nz-tag *ngFor="let tag of material.tags; let i = index" [nzMode]="'default'">
                            <span>{{ tag }}</span>
                        </nz-tag>
                    </div>
                </div>
                <div>
                    <a *ngIf="canPreview(material)" nz-button nzType="link" nzSize="small" appThrottleClick
                       (throttleClick)="onPreview(material)">
                        <em nz-icon nzType="eye" nzTheme="outline"></em>
                    </a>
                    <a nz-button nzType="link" nzSize="small" appThrottleClick (throttleClick)="onDownload(material)">
                        <em nz-icon nzType="cloud-download" nzTheme="outline"></em>
                    </a>
                </div>
            </div>
        </div>


    </div>
</nz-spin>


import { Component, Input, OnInit } from '@angular/core';
import { Information, ReleaseType } from "../../api/types";
import { InformationService } from "../../information/information.service";
import { ActivatedRoute } from "@angular/router";
import { PropertyTextEditType } from "../../shared/components/property-text/property-text-edit.component";
import { PropertySelectOption } from "../../shared/components/property-select/property-select.component";
import { ReleaseTypePipe } from "../../shared/pipes/release-type.pipe";

@Component({
    selector: 'app-public-information',
    templateUrl: './public-information.component.html',
    styleUrls: ['./public-information.component.less']
})
export class PublicInformationComponent implements OnInit {

    @Input() information: Information;

    typeOptions: PropertySelectOption[] = []

    contentTextType = PropertyTextEditType.TEXT_HTML;

    constructor(private route: ActivatedRoute,
                private releaseTypePipe: ReleaseTypePipe,
                private informationService: InformationService,) {
    }

    ngOnInit(): void {

        let releaseTypes: ReleaseType[] = [ReleaseType.ADMINISTRATION_NOTICE, ReleaseType.PRODUCT_NEWS, ReleaseType.GENERAL_INFO, ReleaseType.PRODUCT_PROMOTION]
        this.typeOptions = releaseTypes.map(releaseType => {
            return new PropertySelectOption(this.releaseTypePipe.transform(releaseType), releaseType)
        })

        this.route.params.subscribe(params => {
            console.log(params['id']);
            const id = params['id'];
            this.informationService.info(id)
                .subscribe(
                    data => {
                        this.information = data;
                    },
                    error => {
                    });
        });
    }

}
